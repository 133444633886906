
declare var self: ServiceWorkerGlobalScope;
export { };


export const push_listener = (event: PushEvent) => {
  // Retrieve the textual payload from event.data (a PushMessageData object).
  // Other formats are supported (ArrayBuffer, Blob, JSON), check out the documentation
  // on https://developer.mozilla.org/en-US/docs/Web/API/PushMessageData.
  // @ts-ignore
  let payload: string = event.data ? event.data.text() :
    { "head": "No Content", "body": "No Content", "icon": "" },
    data = JSON.parse(payload).json,
    title = data.title,
    body = data.body,
    icon = data.icon;
  // If no url was received, it opens the home page of the website that sent the notification
  // Whitout this, it would open undefined or the service worker file.
  const url = data.url ? data.url : self.location.origin;
  const push_properties = {
    body: body,
    icon: icon,
    data: {
      url: url
    }
  };

  // Keep the service worker alive until the notification is created.
  event.waitUntil(
    // Show a notification with title 'ServiceWorker Cookbook' and use the payload
    // as the body.
    self.registration.showNotification(title, push_properties)
  );
}


export const notification_click = (event: NotificationEvent) => {
  event.notification.close();
  event.waitUntil(
    self.clients.matchAll({ type: "window" })
      .then(clientList => {
        if (self.clients.openWindow) {
          self.clients.openWindow(event.notification.data.url);
        } else {
          console.error("Non sono riuscito ad aprire la finestra da una notifica push");
        }
      })
  );
}
