
export interface Config {
  version: string,
  precachingItems: string[],
  blacklistCacheItems: string[],
  offlinePage: string,
  offlineImage: string,
  sessionid: string
}


export const config: Config = {
  version: 'versione1::',
  precachingItems: [
    '/api/problems/categoria/',
    '/api/problems/evento/',
  ],
  blacklistCacheItems: [
    '/service-worker.js'
  ],
  offlinePage: "static/offline.html",
  offlineImage: "static/offline.jpg",
  sessionid: "empty"
};
