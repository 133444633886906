import "core-js/stable";
import "regenerator-runtime/runtime";

import { config, Config } from './config';
import { delete_old_caches } from './message'

declare var __DEBUG__: boolean;
const DEBUG = __DEBUG__;


declare var self: ServiceWorkerGlobalScope;
export { };

interface ExtendableEvent extends Event {
  waitUntil(fn: Promise<any>): void;
}


enum ResourceType {
  Content = "Content",
  Image = "Image",
  Static = "Static",
}

const cached_json_contents = [
  /^\/api\/problems\/evento\/$/,
  /^\/api\/problems\/categoria\/$/,
  /^\/api\/problems\/partecipante\/$/,
  /^\/api\/problems\/problemi\/$/,
  /^\/api\/problems\/allegato\/$/,
]

const destroy_cache_urls = [
  /^\/accounts\/logout\/$/,
]



interface FetchEvent extends ExtendableEvent {
  request: Request;
  respondWith(response: Promise<Response> | Response): Promise<Response>;
}


export function cacheName(key: ResourceType, opts: Config): string {
  if (key == ResourceType.Content) {
    return `${opts.version}${opts.sessionid}::${key}`;
  } else {
    return `${opts.version}${key}`;
  }
}

function addToCache(cacheKey: string, request: Request, response: Response) {
  if (response.ok) {
    const copy = response.clone();
    caches.open(cacheKey).then(cache => { cache.put(request, copy); });
  }
  return response;
}

async function fetchFromCache(event: FetchEvent): Promise<Response> {
  return caches.match(event.request).then(response => {
    if (!response) {
      return Promise.reject(new Error(`${event.request.url} not found in cache`));
    }
    return response;
  });
}

function offlineResponse(resourceType: ResourceType, opts: Config) {
  if (resourceType === ResourceType.Image)
    return new Response(opts.offlineImage, { headers: { 'Content-Type': 'image/svg+xml' } });
  if (resourceType === ResourceType.Content)
    return caches.match(opts.offlinePage);
  return undefined;
}



export const fetch_listener = (event: FetchEvent) => {
  const request = event.request;
  const acceptHeader = request.headers.get('Accept');
  const url = new URL(request.url);
  let resourceType = ResourceType.Static;
  if (request.method !== 'GET') {
    return;
  }
  if (url.origin !== self.location.origin) {
    return;
  }
  if (config.blacklistCacheItems.length > 0 &&
    config.blacklistCacheItems.indexOf(url.pathname) >= 0) {
    return;
  }
  if (acceptHeader.indexOf('text/html') !== -1) {
    resourceType = ResourceType.Content;
  } else if (acceptHeader.indexOf('image') !== -1) {
    resourceType = ResourceType.Image;
  } else if (acceptHeader.indexOf('application/json') !== -1) {
    resourceType = ResourceType.Content;
  }

  const cacheKey = cacheName(resourceType, config);

  if (resourceType === ResourceType.Image) {
    cacheFirst(event, request, cacheKey, resourceType);
    return;
  }

  if (resourceType == ResourceType.Static) {
    if (!DEBUG) {
      cacheFirst(event, request, cacheKey, resourceType);
    }
  }

  if (resourceType === ResourceType.Content) {
    // @ts-ignore
    for (const [key, value] of url.searchParams) {
      if (key !== "page") {
        return;
      }
    }
    for (const re of cached_json_contents) {
      if (url.pathname.match(re)) {
        cacheFirstThenNetwork(event, request, cacheKey, resourceType);
        break;
      }
    }
    for (const re of destroy_cache_urls) {
      if (url.pathname.match(re)) {
        delete_old_caches("")
      }
    }
    return;
  }
  return;
}



// Cache first. Non scade mai
const cacheFirst = (event: FetchEvent, request: Request,
  cacheKey: string, resourceType: ResourceType) =>
  event.respondWith(
    fetchFromCache(event)
      .catch(() => fetch(request))
      .then(response => {
        if (response.status == 200) {
          return addToCache(cacheKey, request, response)
        } else {
          return response;
        }
      })
      .catch(() => offlineResponse(resourceType, config)))



const cacheFirstThenNetwork = (event: FetchEvent, request: Request,
  cacheKey: string, resourceType: ResourceType) =>
  event.respondWith(
    fetchFromCache(event).then((response) => {
      const copia = response.clone();
      fetch(request).then((ans) => {
        if (ans.status == 200) {
          addToCache(cacheKey, request, ans);
          ans.json().then(json => {
            self.clients.matchAll().then((clients) => {
              if (clients && clients.length) {
                clients.forEach(client => client.postMessage(
                  JSON.stringify({
                    header: "cached_response",
                    body: {
                      origin: request.url,
                      json: json
                    }
                  })
                ))
              }
            })
          });
        }
      })// .catch()
      return copia;
    }).catch(() => fetch(request))
      .then((ans) => {
        if (ans.status == 200) {
          addToCache(cacheKey, request, ans);
          return ans;
        }
      }))
